// 產生日期（Ex: 生日）需要年、月、日的選項
const monthsDay = {
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

export default {
  data () {
    let yearOptions = []
    let currentYear = (new Date).getYear() + 1900
    for (let year = currentYear; year > currentYear-100; year--) {
      yearOptions.push({ text:year, value:year })
    }
    let monthOptions = []
    for (let month = 1; month <= 12; month++) {
      monthOptions.push({ text:month, value:month })
    }

    return {
      yearOptions,
      monthOptions,      
    }
  },

  methods: {
    monthsDayMap(year) {
      let monthsDayMap = monthsDay
      if (year % 400 == 0
        || (year % 4 == 0 && year % 100 != 0)
      ) {
        monthsDayMap[2] = 29
      } else {
        monthsDayMap[2] = 28
      }
      return monthsDayMap
    },
    dayOptions(year,month) {

      let options = []
      if (!month) {
        return options
      }

      let maxDay = this.monthsDayMap(year)[month];

      for (let day = 1; day <= maxDay; day++) {
        options.push({ text:day, value:day })
      }

      return options
    },
  },
}
